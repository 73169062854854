@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Regular.ttf");
  font-weight: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Black.ttf");
  font-weight: 900;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Bold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("./assets/fonts/Poppins/Poppins-Medium.ttf");
  font-weight: 500;
}

@layer utilities {
  .flex-row-centered {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  .appearance-default {
    -webkit-appearance: none;
  }
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  background-color: #fdfcff;
  -webkit-font-smoothing: antialiased;
}

input:focus,
textarea:focus {
  box-shadow: none !important;
}

.shadow {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.homepage-container {
  max-width: none;
  padding-left: 16px;
  padding-right: 16px;
}

.home-page-giveaway-cards {
  width: 100%;
}

@media (min-width: 1024px) {
  .lg-max-1280px {
    max-width: 1280px;
  }
}

@media (min-width: 760px) {
  .home-page-giveaway-cards {
    width: 740px;
  }
}

@media (min-width: 620px) {
  .homepage-container {
    max-width: 567px;
  }
}

.strokeme {
  color: black;
  font-size: 32px;
  -webkit-text-stroke: 1px black;
  -webkit-text-fill-color: white;
}

.body-bg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-image: radial-gradient(
    140% 119% ellipse at top,
    rgba(105, 201, 102, 0.4) 14%,
    rgba(39, 187, 219, 0.33) 36%,
    rgba(139, 71, 206, 0.2) 52%,
    rgba(218, 64, 134, 0.1) 70%,
    rgba(255, 149, 116, 0) 82%,
    rgba(0, 0, 0, 0) 100%
  );
  opacity: 0.1;
  z-index: -1;
  pointer-events: none;
}
